<template>
	<div>
		<ts-panel>
			<ts-panel-wrapper>
				<fieldset class="mt-2">
					<legend class="text-blue">
						{{ $t('loanRepayment.repaymentInfo') }}
					</legend>
					<div class="row">
						<div class="col-md-12">
							<div class="row mb-2 mt-2">
								<div class="col-lg-4">
									<label class="form-col-label control-label required">{{
										$t(
											'loanRepayment.actualpaymentDate'
										)
									}}</label>
									<DatePicker :value="model.actual_payment_date" placeholder="DD-MM-YYYY" format="dd-MM-yyyy"
										@on-change="onChangeActualPaymentDate" :options="options"></DatePicker>
									<div class="invalid-feedback" v-if="errors.has('actual_payment_date')">
										{{
											errors.first('actual_payment_date')
										}}
									</div>
								</div>
								<div class="col-lg-4">
									<label class="form-col-label control-label required">{{
										$t('loanRepayment.cyclePaymentDate')
									}}</label>
									<DatePicker :value="model.cycle_payment_date" placeholder="DD-MM-YYYY" format="dd-MM-yyyy"
										@on-change="onChangeCyclePaymentDate"></DatePicker>
									<div class="invalid-feedback" v-if="errors.has('cycle_payment_date')">
										{{ errors.first('cycle_payment_date') }}
									</div>
								</div>
								<div class="col-lg-4">
									<label class="form-col-label control-label required">{{
										$t('loanRepayment.paymentType')
									}}</label>
									<select v-model.number="model.payment_type" class="form-select form-select-sm" :class="{
										'is-invalid': errors.has(
											'payment_type'
										)
									}" @change="fetchRepayment">
										<option :value="null" disabled selected style="display: none;">{{ $t('select') }}</option>
										<option v-for="(option,
											index) in paymentType" :value="option.id" :key="index">
											{{ option.label }}
										</option>
									</select>
									<div class="invalid-feedback" v-if="errors.has('payment_type')">
										{{ errors.first('payment_type') }}
									</div>
								</div>
							</div>
							<div class="row mb-2">
								<ts-loading-banner :loading="loanLoading">
									<Select v-model="model.loan_id" :remote-method="loadLoanDisbursement" filterable
										:loading="loanLoading" clearable :placeholder="
											$t(
												'loanRepayment.searchCustomerNameOrLoanNumber'
											)
										" :class="{
	'ivu-form-item-error': errors.has(
		'loan_id'
	)
}" @on-change="fetchRepayment">
										<Option v-for="(option,
											index) in loanDisbursementsList" :value="option.loan_id" :key="index" :label="option.loan_number">
											{{ option.loan_number }} |
											{{ option.customer.customer_code }}
											|
											{{
												option.customer.customer_name_en
											}}
											|
											{{
												option.customer.customer_name_kh
											}}
											|
											{{ option.customer.contact_no }}
										</Option>
									</Select>
								</ts-loading-banner>
								<div class="invalid-feedback" v-if="errors.has('loan_id')">
									{{ errors.first('loan_id') }}
								</div>
							</div>
							<div class="demo-spin-article">
								<div class="tw-overflow-auto" style="height: 45vh;">
									<div class="list-group-item list-group-item-action d-flex" v-for="(item, index) in repaymentItems"
										:key="index">
										<div class="me-1 fs-14px">
											<ts-checkbox v-model="item.is_pay" :disabled="index == 0" />
										</div>
										<div class="flex-fill">
											<div class="tw-flex tw-justify-between">
												<div class="fs-16px lh-12 mb-2px fw-bold text-dark">
													{{
														$root.$i18n.locale ===
														'kh'
														? item.day_kh
														: item.day_en
													}}
													{{
														item.cycle_payment_date
													}}
												</div>
												<div>
													{{
														$t(
															'loanRepayment.isOweCyclePaid'
														)
													}}:
													<span class="badge bg-warning" v-if="
														item.is_owe_cycle_paid
													">{{ $t('yes') }}</span>
													<span v-else class="badge bg-secondary">{{ $t('no') }}</span>
												</div>
											</div>
											<div class="fs-12px">
												<div class="text-gray-600 flex-1">
													{{
														$t(
															'loanRepayment.amountExcess'
														)
													}}:
													{{
														formatCurrencyWithCode(
															item.amount_excess,
															currency
														)
													}}
												</div>
											</div>
											<div class="mb-1 fs-12px" v-if="model.payment_type == 3">
												<div class="text-gray-600 flex-1">
													{{
														$t(
															'loanRepayment.payOffPenalty'
														)
													}}:
													{{
														formatCurrencyWithCode(
															item.pay_off_penalty_amount,
															currency
														)
													}}
												</div>
											</div>
											<div class="tw-flex tw-justify-between tw-uppercase tw-font-bold">
												{{
													$t(
														'loanRepayment.totalAmount'
													)
												}}:
												<span class="badge bg-primary fs-14px" v-if="
													model.payment_type == 3
												">{{
	formatCurrencyWithCode(
		item.total_amount -
		item.pay_off_penalty_amount,
		currency
	)
}}</span>
												<span class="badge bg-primary fs-14px" v-else>{{
													formatCurrencyWithCode(
														item.total_amount -
														item.amount_excess,
														currency
													)
												}}</span>
											</div>
											<hr class="mb-10px bg-gray-600" />
											<div class="d-flex align-items-center mb-5px">
												<div class="fs-12px d-flex align-items-center text-dark fw-bold">
													{{
														$t(
															'loanRepayment.repaymentItems'
														)
													}}
												</div>
												<div class="ms-auto">
													<a v-b-toggle :href="
														'#accordion' + index
													" @click.prevent
														class="btn btn-outline-default text-gray-600 btn-xs rounded-pill fs-10px px-2 not-collapsed"
														style="overflow-anchor: none;">
														{{
															$t(
																'loanRepayment.collapse'
															)
														}}
													</a>
												</div>
											</div>
											<b-collapse :id="'accordion' + index" :visible="index == 0" accordion="my-accordion">
												<!-- begin widget-list -->
												<div class="widget-list rounded">
													<!-- begin widget-list-item -->
													<a href="#" class="widget-list-item" v-for="(sub,
														i) in itemDetails(
		item.repayment_json
	)" :key="i">
														<div class="widget-list-content">
															<h4 class="widget-list-title">
																{{ i + 1 }}
																{{
																	sub.repayment_item
																}}
															</h4>
														</div>
														<div class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none">
															{{
																formatCurrencyWithCode(
																	sub.repayment_amount,
																	currency
																)
															}}
														</div>
													</a>
													<!-- end widget-list-item -->
												</div>
												<!-- end widget-list -->
											</b-collapse>
										</div>
									</div>
								</div>
								<div class="invoice">
									<div class="invoice-content">
										<div class="invoice-price">
											<div class="invoice-price-left">
												<div class="invoice-price-row">
													<div class="sub-price">
														<small>SUBTOTAL</small><span class="text-dark">{{
															formatCurrencyWithCode(
																subtotal -
																totalPayOffPenalty,
																currency
															)
														}}</span>
													</div>
													<div class="sub-price" v-if="
														model.payment_type ==
														3
													">
														<small>Pay-off
															Penalty</small><span class="text-dark">{{
																formatCurrencyWithCode(
																	totalPayOffPenalty,
																	currency
																)
															}}</span>
													</div>
													<div class="sub-price" v-else>
														<i class="fa fa-minus text-muted"></i>
													</div>
													<div class="sub-price">
														<small>Amount
															Excess</small><span class="text-dark">{{
																formatCurrencyWithCode(
																	totalAmountExcess,
																	currency
																)
															}}</span>
													</div>
												</div>
											</div>
											<div class="invoice-price-right">
												<small>GRAND TOTAL</small>
												<span class="fw-bold">{{
													formatCurrencyWithCode(
														subtotal -
														totalAmountExcess,
														currency
													)
												}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Spin size="large" fix v-if="loading"> </Spin>
							<form class="form-horizontal form-horizontal-text-right">
								<div class="row mb-2">
									<label class="form-label col-form-label col-md-7">{{
										$t(
											'loanRepayment.actualPaymentAmount'
										)
									}}</label>
									<div class="col-md-5">
										<Poptip trigger="focus">
											<Input :placeholder="
												$t('loanRepayment.enterNumber')
											" v-model="model.amount_paid" :class="{
	'ivu-form-item-error': errors.has('amount_paid'
	)
}" size="large">
												<i class="fas fa-dollar-sign" slot="prefix" v-if="
													currency.currency_id ==
													1
												"></i>
												<i class="far fa-registered" slot="prefix" v-if="
													currency.currency_id ==
													2
												"></i>
												<i class="fas fa-bold" slot="prefix" v-if="
													currency.currency_id ==
													3
												"></i>
											</Input>
											<div slot="content">
												{{
													model.amount_paid
													? formatNumber(
														model.amount_paid,
														currency.currency_id
													)
													: $t(
														'loanRepayment.enterNumber'
													)
												}}
											</div>
										</Poptip>
									</div>
								</div>
								<div class="row mb-2">
									<label class="form-label col-form-label col-md-7">{{
										$t(
											'loanRepayment.refVoucher'
										)
									}}</label>
									<div class="col-md-5">
										<Input :placeholder="
											$t('loanRepayment.refVoucher')
										" v-model="model.ref_voucher" :class="{ 'ivu-form-item-error': errors.has('ref_voucher') }" size="large">
										</Input>
									</div>
								</div>
								<div class="row mb-2">
									<label class="form-label col-form-label col-md-7">{{
										$t('loanRepayment.penaltyWaive')
									}}</label>
									<div class="col-md-5">
										<Poptip trigger="focus">
											<Input :placeholder="
												$t(
													'loanRepayment.enterNumber'
												)
											" v-model="model.penalty_waive" :class="{ 'ivu-form-item-error': errors.has('loan_amount') }" size="large">
												<i class="fas fa-dollar-sign" slot="prefix" v-if="
													currency.currency_id ==
													1
												"></i>
												<i class="far fa-registered" slot="prefix" v-if="
													currency.currency_id ==
													2
												"></i>
												<i class="fas fa-bold" slot="prefix" v-if="
													currency.currency_id ==
													3
												"></i>
											</Input>
											<div slot="content">
												{{
													model.penalty_waive
													? formatNumber(
														model.penalty_waive,
														model.currency_id
													)
													: $t(
														'loanRepayment.enterNumber'
													)
												}}
											</div>
										</Poptip>
									</div>
								</div>
							</form>
						</div>
					</div>
				</fieldset>
			</ts-panel-wrapper>
		</ts-panel>
	</div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapGetters, mapState } from "vuex";
import { orderBy, debounce } from "lodash";

export default {
	name: 'repaymentInfo',
	props: ['value', 'validation'],
	data() {
		return {
			model: {},
			loading: false,
			loanLoading: false,
			errors: new Errors(),
			repaymentItems: [],
			paymentType: [
				{
					id: 1,
					label: 'Repayment'
				},
				// {
				//     id: 2,
				//     label: 'Reschedule'
				// },
				{
					id: 3,
					label: 'Pay Off'
				}
			],
			options: {
				disabledDate(date) {
					return date.valueOf() > Date.now()
				}
			}
		}
	},
	created() {
		this.model = this.value
	},
	computed: {
		...mapState('creditOperation/loanRepayment', [
			'edit_data',
			'loanDisbursements'
		]),
		...mapGetters(['formatNumber', 'formatCurrencyWithCode']),
		loanDisbursementsList() {
			return orderBy(this.loanDisbursements, ['loan_number'], ['asc'])
		},
		currency() {
			if (this.model.loan_id) {
				let dis = this.loanDisbursements.find(
					l => l.loan_id === this.model.loan_id
				)
				if (dis) {
					return dis.currency
				}
				return {
					currency_id: this.$store.state.authUser.sys_setting[0]
						.default_currency_id,
					currency_code: this.$store.state.authUser.sys_setting[0]
						.default_currency
				}
			}
			return {
				currency_id: this.$store.state.authUser.sys_setting[0]
					.default_currency_id,
				currency_code: this.$store.state.authUser.sys_setting[0]
					.default_currency
			}
		},
		payOnlyCheckItems() {
			if (this.repaymentItems.length > 0) {
				return this.repaymentItems.filter(r => r.is_pay === true)
			}
			return []
		},
		subtotal() {
			if (this.payOnlyCheckItems.length > 0) {
				return this.payOnlyCheckItems.reduce(
					(n, { total_amount }) => n + total_amount,
					0
				)
			}
			return 0
		},
		totalPayOffPenalty() {
			if (this.payOnlyCheckItems.length > 0) {
				if (this.model.payment_type == 3) {
					//pay-off
					return this.payOnlyCheckItems.reduce(
						(n, { pay_off_penalty_amount }) =>
							n + pay_off_penalty_amount,
						0
					)
				}
				return 0
			}
			return 0
		},
		totalAmountExcess() {
			if (this.payOnlyCheckItems.length > 0) {
				return this.payOnlyCheckItems.reduce(
					(n, { amount_excess }) => n + amount_excess,
					0
				)
			}
			return 0
		}
	},
	methods: {
		...mapActions('creditOperation/loanRepayment', ['getLoanDisbursement']),
		loadLoanDisbursement: debounce(function (query) {
			this.loanLoading = true
			this.getLoanDisbursement({
				search: query,
				cycle_payment_date: this.model.cycle_payment_date
			}).finally(() => {
				this.loanLoading = false
			})
		}, 1000),
		fetchRepayment() {
			if (
				this.model.cycle_payment_date &&
				this.model.loan_id &&
				this.model.payment_type
			) {
				this.loading = true
				this.$store
					.dispatch('creditOperation/loanRepayment/fetchRepayment', {
						loan_id: this.model.loan_id,
						actual_payment_date: this.model.actual_payment_date,
						cycle_payment_date: this.model.cycle_payment_date,
						payment_type: this.model.payment_type
					})
					.then(response => {
						this.repaymentItems = response.data
					})
					.catch(error => {
						this.errors = new Errors(error.errors)
						this.notice({ type: 'error', text: error.message })
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		onChangeActualPaymentDate(date) {
			this.model.actual_payment_date = date
		},
		onChangeCyclePaymentDate(date) {
			this.loanLoading = true
			this.repaymentItems = []
			this.model.cycle_payment_date = date
			this.getLoanDisbursement({
				cycle_payment_date: this.model.cycle_payment_date
			})
				.then(() => {
					if (this.loanDisbursements.length == 0) {
						this.model.loan_id = null
					}
				})
				.finally(() => {
					this.loanLoading = false
				})
			this.fetchRepayment()
		},
		itemDetails(items) {
			return orderBy(items, ['payment_priority'], ['asc'])
		}
	},
	watch: {
		validation: {
			handler() {
				this.errors = new Errors(this.validation.errors)
			},
			deep: true
		},
		payOnlyCheckItems: {
			handler() {
				this.model.amount_paid = this.subtotal - this.totalAmountExcess
			},
			deep: true
		}
	}
}
</script>
<style scoped>
.invoice {
	padding: 0 !important;
}

.widget-list .widget-list-item .widget-list-media,
.widget-list .widget-list-item .widget-list-content,
.widget-list .widget-list-item .widget-list-action {
	padding: 0.2rem 0.8rem;
}

.invoice .invoice-price .invoice-price-left {
	padding: 14px;
	font-size: 14px;
}

.invoice .invoice-price .invoice-price-right {
	padding: 14px;
	font-size: 14px;
}

.invoice .invoice-price .invoice-price-right small,
.invoice .invoice-price .invoice-price-right .small {
	top: 5px;
}
</style>
